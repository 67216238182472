/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 64.2.0-v202404042022
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqUnstableApiClass {
 
   constructor() {}

  /**
   * @summary Get raw cache data associated with the supplied cacheId.
   * @param {string} cacheId
   * @param {string} start - An 8601 timestamp corresponding to the key at the beginning of the query interval.
   * @param {string} exclusiveEnd - An 8601 timestamp corresponding to the key at the end of the query interval.
   * @param {boolean} metadataOnly - If set to true, the endpoint will only return cache record metadata (no decompression).
   * @param {string} [maxDatums] - The maximum number of datums to retrieve from the persistent cache. If a request exceeds this value, the result will be truncated. Cannot be larger than: 1048576 unless &#x60;metadataOnly&#x60; is true.
   * @param {string} [overrideTimeout] - A duration, in minutes, that should be used as a timeout for the GRPC connection. Default: 2 minutes.
   */
  public getCache(
    {
      cacheId,
      start,
      exclusiveEnd,
      metadataOnly,
      maxDatums,
      overrideTimeout
    } : {
      cacheId: string,
      start: string,
      exclusiveEnd: string,
      metadataOnly: boolean,
      maxDatums?: string,
      overrideTimeout?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(cacheId)) {
      throw new Error("'cacheId' parameter required");
    }

    if (isNil(start)) {
      throw new Error("'start' parameter required");
    }

    if (isNil(exclusiveEnd)) {
      throw new Error("'exclusiveEnd' parameter required");
    }

    if (isNil(metadataOnly)) {
      throw new Error("'metadataOnly' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/cache/${encodeURIComponent(String(cacheId))}/blocks`,

      params: omitBy({
        ['start']: start,
        ['exclusiveEnd']: exclusiveEnd,
        ['maxDatums']: maxDatums,
        ['metadataOnly']: metadataOnly,
        ['overrideTimeout']: overrideTimeout
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.DebugCacheBlockOutputV1>;
  }

}


export const sqUnstableApi = new sqUnstableApiClass();
